.limits-item {
    border-bottom: 1px solid rgba(255,255,255,.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    height: 34px;
    width: 100%;
    padding: 12px 5px 12px 10px;

    &_notNeedBorder {
        border-bottom: unset;
    }

    &__limits-amount-container {
        border-radius: 11px;
        background-color: #123405;
        border: 1px solid #427f00;
        font-size: 14px;
        font-weight: 400;
        padding: 3.5px 10px;
        line-height: 1;
    }
}