.menu-item {
    width: 100%;
    height: 44px;
    padding: 7px 10px;
    background: #1b1c1d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    
    &_isNeedBorder {
        border-top: solid 1px rgba(255,255,255,.1);
    }

    &_isPointer {
        cursor: pointer;
    }

    &__lable {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 200;
    }

    &__icon {
        height: 16px;
        width: auto;
        // width: 24px;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
}