.history {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 10px  10px 0 0;
  background: #1B1C1D;
  overflow: hidden;
  box-sizing: border-box;
  font-size: .9rem;

  &_isClosingHistory {
    animation: reduceHistory 0.3s linear forwards;
  }
  &__buttons-container {
    min-height: 24px;
    max-height: 24px;
    font-size: 12px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #141516;
    border-radius: 20px;
    border: solid 1px #141516;
    width: fit-content;
  }
  &__component-container {
    height: 100%;
    overflow: auto;
  }
  
  &__footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (min-width: 700px) {
  .history {
    position: inherit;
    animation: none;
    border-radius: 10px;
  }
}

@keyframes increaceHistory {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
}

@keyframes reduceHistory {
  0% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}