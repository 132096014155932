.basket-item {
    box-sizing: border-box;
    font-weight: 400;
    line-height: 1.5;
    border: unset;
    width: 100%;
    height: 170px;
    max-width: 750px;
    min-width: 310px;
    border-radius: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #1b1c1d;
    margin: 0 auto;
    @media screen and (max-width: 640px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        column-gap: 15px;
    }

    &_border {
        &_cashout {
            border: solid 1px #d07206;
        }
        &_cancel-bet {
            border: solid 1px #cb011a;
        }
        &_cancel-pre-bet {
            border: solid 1px #cb011a;
        }
    }
    @media only screen and (max-width: 640px) {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 auto;
    }

    &__control-mode-block {
        height: 24px;
        width: 55%;
        font-size: 12px;
        margin-bottom: auto;
        margin-top: 10px;
        background: #141516;
        border-radius: 10px;
        border: solid 1px #141516;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        @media only screen and (max-width: 640px) {
            margin-bottom: 10px;
        }
    }

    &__mode {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 50%;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;
        &_isActiveMode {
            background: rgb(44, 45, 48);
        }
    }

    &__container {
        width: calc(100% - 20px);
        max-width: 390px;
        max-height: 78px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 0px 10px;
        margin-bottom: auto;
        @media only screen and (max-width: 640px) {
            margin-bottom: 18px;
        }
        &_isAutoMode {
            margin-bottom: 10px;
        }
    }

    &__auto-cashout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 5px;
        box-sizing: border-box;
        font-size: 12px;
    }

    &__auto-cashout-swither {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        column-gap: 5px;
    }

    &__auto-cashout-amount {
        display: flex;
        align-items: center;
        margin-left: 5px;
        box-sizing: border-box;
        height: 30px;
        width: 80px;
        font-size: 16px;
        justify-content: space-between;
        border-radius: 22px;
        background-color: #000000b3;
        font-weight: 700;
        line-height: 1;
        padding-left: 10px;
        padding-right: 5px;
    }

    &__auto-mode-container {
        border-top: 2px solid #141516;
        display: flex;
        width: 99%;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        color: #9ea0a3;
        box-sizing: border-box;
    }

    &__bet-amount-container {
        width: 41%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
    }

    &__change-container {
        font-size: 18px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity .1s;
        border-radius: 22px;
        background-color: #000000b3;
        font-weight: 700;
        line-height: 1;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        
    }

    &__text {
        display: flex;
        align-items: center;
        height: 100%;
        margin-bottom: 2px;
        &_isBlure {
            opacity: .5;
        }
    }

    &__change-amount-icon {
        width: 18px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__chage-amount-input-conteiner {
        width: calc(100% - 36px);
    }
    &__terms-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 14px;
        color: #9ea0a3;
        width: 100%;
        box-sizing: border-box;
        padding: 4px;
        column-gap: 4px;
        row-gap: 4px;
    }

    &__term {
        font-size: 14px;
        width: 45%;
        height: 18px;
        line-height: 1;
        padding: 0;
        background: #141516;
        border: none;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
    }

    &__bet-button {
        width: 59%;
        padding-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
    }

    &__text-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 5px;
    }

    &__button-title {
        font-size: 21px;
        display: flex;
        align-items: flex-end;
        column-gap: 5px;
        @media only screen and (max-width: 422px) {
            font-size: 24px;
          }
    }

    &__button-curency {
        font-size: 18px;
        @media only screen and (max-width: 422px) {
            font-size: 18px;
          }
    }
    // &__bets-container {
    //     display: flex;
    //     width: 100%;
    //     height: 100%;
    //     justify-content: space-between;
    //     text-transform: uppercase;
    //     box-sizing: border-box;
    //     flex-direction: column;
    //     &_isAlone {
    //         flex-direction: row;
    //         @media only screen and (max-width: 640px) {
    //             flex-direction: column;
    //         }
    //     }
    // }

    // &__left {
    //     width: 100%;
    //     padding-right: 15px;
    //     height: 100%;
    //     margin-bottom: 9px;
    //     box-sizing: border-box;
    //     &_isAlone {
    //         width: 55%;
    //         border-right: 1px solid rgba(89, 95, 118, 0.4);
    //         @media only screen and (max-width: 640px) {
    //             width: 100%;
    //             padding-right: 0;
    //             border-right: none;
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    // &__left-content {
    //     display: flex;
    //     width: 100%;
    //     align-items: center;
    //     justify-content: space-between;
    //     column-gap: 9px;
    // }

    // &__content-container {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     justify-content: center;
    //     @media only screen and (max-width: 640px) {
    //         width: 50%;
    //     }
    // }

    // &__label {
    //     margin-bottom: 15px;
    //     color: white;
    //     @media only screen and (max-width: 640px) {
    //         font-size: 12px;
    //     }
    // }

    // &__row {
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     height: 22.6%;
    //     box-sizing: border-box;
    //     margin-bottom: 25px;
    //     @media only screen and (max-width: 640px) {
    //         margin-bottom: 12px;
    //     }
    //     &_size {
    //         &_big {
    //             height: 50%;
    //         }
    //     }
    // }

    // &__change-container {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     justify-content: space-around;
    //     align-items: center;
    //     border: 1px solid #595f76;
    //     box-sizing: border-box;
    //     border-radius: 10px;
    //     background: rgba(192, 203, 218, 0.2);
    //     border-radius: 10px;
    //     color: white;
    //     height: 46px;
    //     font-size: 20px;
    //     box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.2);
    // }

    // &__change-block {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 100%;
    //     width: 100%;
    //     box-sizing: border-box;

    //     &_isBlock {
    //         opacity: .3;

    //         * {
    //             cursor: not-allowed;
    //         }
    //     }
    // }

    // &__change-currency {
    //     background: #595f76;
    //     height: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     border-radius: 0 10px 10px 0;
    //     width: 3.4rem;
    //     line-height: 39px;
    //     font-size: 19px;
    //     color: white;
    //     box-sizing: border-box;
    //     padding: 15px;
    // }

    // &__text {
    //     white-space: nowrap;
    // }

    // &__bet-multipliers-container {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     column-gap: 9px;
    //     margin-bottom: 9px;
    // }

    // &__bet-block {
    //     width: 28%;
    //     height: 100%;
    //     padding: 13px 0px 12px 0px;
    //     font-size: 17.5px;
    //     background: #c0cbda;
    //     border-radius: 10px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     color: black;
    //     transition: all 0.2s ease-in;
    //     box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    //     cursor: pointer;
    //     text-align: center;

    //     &_size {
    //         &_big {
    //             width: calc(50% - .25rem);
    //         }
    //     }
    // }

    // &__right {
    //     width: 100%;
    //     height: 100%;
    //     border-left: none;
    //     display: flex;
    //     box-sizing: border-box;
    //     align-items: center;
    //     justify-content: center;
    //     &_isAlone {
    //         width: 45%;
    //         padding: 0 0 0 15px;
    //         margin-top: 9px;
    //         @media only screen and (max-width: 640px) {
    //             width: 100%;
    //             margin-top: 0;
    //             padding: 0;
    //         }
    //     }

    // }
}