.subheader {
  background: #1b1c1d;
  width: 100%;
  height: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 5px;

  &__logo {
    width: 72px;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
  }

  &__rules-mini {
    height: 100%;
    border: 1px solid #ffbd71;
    background: #e69308;
    color: #5f3816;
    display: flex;
    border-radius: 20px;
    column-gap: 5px;
    padding: 0 5px;
    align-items: center;
    cursor: pointer;
    &_isMobile {
      width: 26px;
      border-radius: 50%;
      justify-content: center;
      padding: unset;
    }
  }

  &__question {
    width: 16px;
    height: 16px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__balance {
    font-size: 16px;
    font-weight: bold;
    color: #28a909;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 10px;
    border-right: 1px solid #464648;
    column-gap: 5px;
  }

  &__currency {
    color: #9b9c9e;
    font-size: 12px;
    font-weight: lighter;
  }

  &__menu {
    width: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 10px;
    cursor: pointer;
  }

  &__menu-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__guard {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__guard-icon {
    width: 2.5rem;
    max-height: 2.5rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 2rem;
      * {
        fill: #FF8D00;
      }
    }
  }

  &__icon {
    width: 2.15rem;
    max-height: 2.15rem;
    display: block;
    line-height: 0;
    position: relative;
    opacity: 0.7;
    &-svg {
      max-height: 2.15rem;
    }
    &:hover {
      opacity: 1;
    }
  }
}