.auto-bet-settings {
    width: 100%;
    background-color: #2c2d30;
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    font-size: 14px;
    line-height: 14px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #d2d2d2;
    font-weight: 300;

    &__switch-settings-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
    }

    &__change-amount-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 5px;
        &_notActive {
            opacity: .5;
        }
    }
}