.socket-error {
    display: flex;
    flex-direction: column;
    color: white;
    background: black;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 82px;

}