.game-window {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: transparent;

  &_styleCode {
    &_default {
      &.game-window_isAnimation {
        animation: backgroundAnimation 45s linear infinite, backgroundVerticalAnimation 45s linear forwards;
      }
    }
    }

  &_isPauseAnimation {
    animation-play-state: paused !important;
  }
  &__end-image {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 10rem;
  }

  &__text {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: 400;
    font-size: 34px;
    text-transform: uppercase;
    color: white;

    @media screen and (max-width: 990px) {
      top: 10%;
    }

    @media screen and (max-height: 640px) {
      font-size: 22px;
      top: 20%;
    }
  }
}

@media screen and (min-width: 700px) {
  .game-window {
    padding-top: 0;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 200%;
  }
}

@keyframes backgroundVerticalAnimation {
  0% {
    background-position-y: 100%;
  }
  100% {
    background-position-y: 0%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.bg {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 800%;
  border-radius: 100%;
  background: url('../../../../services/img/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1/1;
  transform: rotate(0deg);
  animation: 25s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}