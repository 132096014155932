.pause {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: url('../../../../services//img/bg.png') no-repeat;
  background-position: -1380px -720px;
  // background-position-x: 58%;
  // background-position-y: 45%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 34px;
  row-gap: 15px;
  border-radius: 10px;
  text-transform: uppercase;

  @media screen and (max-width: 990px) {
    background-position-y: -1054px;
  }

  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    font-size: 18px;
    background-position-y: 45%;
  }

  &__aviator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 112px;
  }

  &__animation {
    width: 180px;
    height: 6px;
    background: #E50539;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__progress {
    animation-name: wickAnimation;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    background: #464c63;
    border-radius: 2px;
    height: 6px;
  }

  &__icon {
    width: 13%;
    margin-bottom: 10%;
    aspect-ratio: 1/1;
    animation: 2s linear infinite rotation;
    @media screen and (max-height: 990px) {
      margin-bottom: 5%;
    }
    @media screen and (max-width: 640px) {
      height: 66px;
      width: 66px;
      justify-content: flex-end;
      margin-bottom: unset;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__airplane {
    position: absolute;
    bottom: 3%;
    left: 1%;

    @media screen and (max-width: 640px) {
      transform: scale(0.6);
      bottom: unset;
      top: 147px;
      left: 9px;
    }
  }
}

@keyframes wickAnimation {
  0% {
    width: 0
  }

  100% {
    width: 100%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}