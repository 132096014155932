.input {
  width: 100%;
  min-height: 2.5em;
  text-align: left;
  outline: 0;
  font-size: 1rem;
  background: none;
  color: #FFF;
  border: none;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;

  &__label {
    position: absolute;
    color: #FFF;
    z-index: 0;
  }

  &__label-text {
    margin-left: 0.2rem;
  }

  &__transparent-text {
    color: transparent;
  }

  &_type {
    &_textarea {
      height: 100%;
      width: 100%;
      border: none;
      vertical-align: top;
      resize: none;
      color: #8d97a1;
      display: flex;
      align-items: center;
    }
  }

  &_unBorder {
    border: none;
  }

  &_centerText {
    text-align: center;
    padding: 0 5px;
    font-size: 18px;
    min-height: 34px;
    font-size: unset;
    border-radius: unset;
  }

  &_leftText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    font-weight: 700;
    line-height: 1;
    color: #9ea0a3;
    min-height: unset;
    height: 100%;
  }

  &_isBlure {
    opacity: .5;
  }


  &::-webkit-calendar-picker-indicator {
    filter: invert(1)
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__textarea-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 1rem 0 1rem;
    width: 100%;
  }

  &__icon-container {
    width: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: auto;
  }

  &__textarea-icon {
    width: 100%;
    height: 100%;
    color: rgba(141, 151, 161, 0.7);
    &:hover {
      color: rgba(141, 151, 161, 1);
    }
  }
}
