.edit-user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 1000;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  padding: 8px;
  background: rgba(17, 19, 24, 0.3);

  @media screen and (max-width: 640px) {
    justify-content: flex-start;
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: #1f212e;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    animation: openComponent .3s ease-out;
    max-width: 500px;

    @media screen and (max-width: 640px) {
      max-width: unset;
    }

    &_isClose {
      animation: closeComponent .3s ease-out;
    }
  }

  &__svg {
    height: 13px;
    width: 13px;
    color: #97a4ae;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    padding: 0 10px;
    height: 36px;
    min-height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    background: #2c2d30;
    border-radius: 8px 8px 0 0;
    font-weight: 400;
    box-sizing: border-box;
    color: white;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    height: calc(100% - 94px);
    box-sizing: border-box;
    background: #1f212e;
    padding: 16px;
  }

  &__footer {
    width: 100%;
    height: 58px;
    padding: 10px;
    background: #2c2d30;
    border-radius: 0 0 8px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #83878e;
    padding: 6px 12px;
    font-size: 16px;
    cursor: pointer;
    width: auto;
    border: solid 1px #414148;
    border-radius: 21px;
  }

  &__icons-block {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  &__icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid #333;
    background-position: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    box-sizing: border-box;

    &_active {
      border: 4px solid #428a12;
      cursor: default;
    }
  }

  // display: flex;
  // flex-direction: column;
  // height: 324px;
  // width: 400px;
  // top: 4.1rem;
  // border-radius: 4px;
  // background: #2a2e3b;
  // z-index: 101;
  // overflow: hidden;
  // overflow-y: scroll;
  // box-sizing: border-box;
  // padding: 18px 9px 2px;
  // max-height: calc(100% * 0.3);
  // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  // @media only screen and (max-width: 422px) {
  //   width: 90%;
  // }
  // &__icons-block {
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;
  // }
  // &__icon {
  //   width: calc(100% / 5);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 100%;
  //   box-sizing: border-box;
  //   padding: .3rem;
  //   background: transparent;
  //   transition: all .5s linear;

  //   &_active {
  //     background: #2ecd45;
  //   }

  //   &-svg {
  //     width: 100%;
  //   }
  // }

  // &__top-block {
  //   display: flex;
  //   width: 100%;
  //   margin-bottom: 0.5rem;
  //   align-items: center;
  // }

  // &__item {
  //   width: 2.5rem;
  //   height: 2.5rem;
  //   max-height: 2.5rem;
  //   min-width: 2.5rem;
  //   border-radius: 100%;
  //   margin-left: 0.6rem;
  //   background: #FF8D00;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   cursor: pointer;
  //   &:last-child {
  //     background: #13171D;
  //   }
  // }

  // &__save,
  // &__close {
  //   width: 1.1rem;
  //   max-height: 1.1rem;
  //   display: block;
  //   line-height: 0;
  //   &-svg {
  //     max-height: 1.1rem;
  //     * {
  //       fill: #FFF;
  //     }
  //   }
  // }
}

@keyframes increaceEdit {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }

  100% {
    padding: 1rem;
    max-height: calc(100% * 0.3);
  }
}

@keyframes reduceEdit {
  0% {
    padding: 1rem;
    max-height: calc(100% * 0.3);
  }

  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}

@keyframes increaceEditDesk {
  0% {
    max-height: 0;
    border-width: 0;
    padding: 0 1rem;
  }

  100% {
    padding: 1rem;
    border-width: 10px;
    max-height: 16rem;
  }
}

@keyframes reduceEditDesk {
  0% {
    padding: 1rem;
    border-width: 10px;
    max-height: 16rem;
  }

  100% {
    max-height: 0;
    border-width: 0;
    padding: 0 1rem;
  }
}