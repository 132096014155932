.notify-item {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1000000;
  box-sizing: border-box;
  font-size: 1rem;
  color: #FFF;
  animation: 0.7s ease init forwards;
  justify-content: space-around;
  border-radius: 26px;
  background-color: #383a3e;
  text-align: center;
  height: 56px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 0 8px #0000004d;
  border: 1px solid #2c2d30;

  &_type {
    &_error {
      border-left: 5px solid #AC2F2F;
    }
  
    &_success {
      border-left: 5px solid #FF8D00;
    }

    &_win {
      position: relative;
      justify-content: space-around;
      border-radius: 26px;
      background-color: #1B320D;
      text-align: center;
      display: flex;
      height: 56px;
      align-items: center;
      padding: 5px 10px;
      margin-bottom: 1rem;
      box-shadow: 0 0 8px #0000004d;
      border: 1px solid #517D22;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 18px;
    height: 100%;
    padding: 5px 10px;

    &_isAmount {
      font-weight: 500;
      background: url('../../../../services/img/notification-left.png') left no-repeat,
      url('../../../../services/img/notification-right.png') right no-repeat;
      background-color: #68AB36;
      border-radius: 26px;
      padding: 5px 30px;
    }
  }

  &__text {
    font-size: 12px;
    color: #8B918E;
    &_isAmount {
      color: white;
      font-weight: 500;
    }
  }


  &_deleting {
    animation: 0.7s ease delete;
  }

  &__img {
    width: 2rem;
    line-height: 0;
  }

  &__close {
    min-width: 12px;
    color: #778370;
    cursor: pointer;
  }

  @keyframes init {
    0% {
      // opacity: 0;
      top: 200%;
    }
    100% {
      // opacity: 1;
      top: 0%;
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      max-height: 3rem;
      min-height: 3rem;
      overflow: hidden;
      margin-top: 0.5rem;
      top: 0%;
    }
    70% {
      opacity: 0.5;
      top: 120%;
      margin-top: 0rem;
      padding: 0;
    }
    100% {
      opacity: 0;
      top: 200%;
      max-height: 0;
      min-height: 0;
      margin-top: 0rem;
      padding: 0;
      overflow: hidden;
    }
  }
}