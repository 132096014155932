.change-amount {
    font-size: 18px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity .1s;
    border-radius: 22px;
    background-color: #000000b3;
    font-weight: 700;
    line-height: 1;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    &__icon {
        width: 18px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__input-conteiner {
        width: calc(100% - 36px);
    }
}