.my-bets-history {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(17, 19, 24, 0.4);
    box-sizing: border-box;
    padding: 8px;
    @media screen and (max-width: 640px) {
        justify-content: flex-start;
    }

    &::-webkit-scrollbar {
        display: none;
      }

    &__container {
        width: 100%;
        max-height: 100%;
        background: #1b1c1d;
        border-radius: 8px;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 640px) {
            max-width: unset;
        }
    }

    &__header {
        padding: 0 10px;
        height: 36px;
        min-height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        background: #2c2d30;
        border-radius: 8px 8px 0 0;
        font-weight: 400;
        box-sizing: border-box;
        color: white;
        text-transform: uppercase;
    }

    &__svg {
        height: 13px;
        width: 13px;
        color: #97a4ae;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__content {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-bottom: 10px;
        overflow-y: scroll;
    }

    &__labels {
        font-size: 11px;
        color: #7b7b7b;
        min-height: 20px;
        max-height: 20px;
        padding: 0px 10px 0px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        &_isDate {
            width: 16.47%;
        }

        &_isBet {
            width: 29.26%;
            justify-content: flex-end;
        }

        &_isCoef {
            width: 5%;
            padding-left: 10px;
        }

        &_isCashOut {
            width: 42.95%;
            margin-left: auto;
            justify-content: flex-end;
        }
    }

    &__item {
        width: 100%;
        height: 34px;
        margin-top: 2px;
        border-radius: 8px;
        background-color: #101112;
        border: solid 1px #101112;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 5px;
        box-sizing: border-box;

        &_isWin {
            border-color: #427f00;
            background-color: #123405;
        }
    }

    &__created-date {
        box-sizing: border-box;
        font-size: 12px;
        color: #bbbfc5;
        line-height: 1;
        display: flex;
        flex-wrap: wrap;
        width: 16.33%;
        padding-right: 6.24px;
    }

    &__amount {
        box-sizing: border-box;
        display: flex;
        width: 29.26%;
        justify-content: flex-end;
        font-weight: 300;
    }

    &__coeff {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 11px;
        border-radius: 11px;
        color: rgb(52, 180, 255);
        background-color: #00000080;
        font-size: 12px;
        min-width: 15.6%;
        margin-left: 10px;
        font-weight: 700;

    }

    &__win-amount {
        box-sizing: border-box;
        display: flex;
        width: 29.26%;
        justify-content: flex-end;
        margin-left: auto;
        padding-right: 5px;
        font-weight: 300;
    }

    &__footer {
        width: 100%;
        padding: 5px 0px 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }

    &__load-more-button {
        border: 1px solid #36363c;
        border-radius: 100px;
        background-color: #252528;
        color: #83878e;
        text-align: center;
        box-shadow: none;
        cursor: pointer;
        padding: 4px 20px;
        box-sizing: border-box;
        font-weight: 400;
        line-height: 1.5;
    }
}