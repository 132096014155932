.game-limits {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(17, 19, 24, 0.4);
    box-sizing: border-box;
    @media screen and (max-width: 640px) {
        justify-content: flex-start;
    }

    &__container {
        width: 100%;
        background: #1b1c1d;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 500px;
        @media screen and (max-width: 640px) {
            max-width: unset;
        }
    }

    &__header {
        padding: 0 10px;
        height: 36px;
        min-height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        background: #2c2d30;
        border-radius: 8px 8px 0 0;
        font-weight: 400;
        box-sizing: border-box;
        color: white;
        text-transform: uppercase;
    }

    &__svg {
        height: 13px;
        width: 13px;
        color: #97a4ae;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__content {
        box-sizing: border-box;
        width: 100%;
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__limits-container {
        border-radius: 8px;
        color: #fff;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(255,255,255,.1);
        box-sizing: border-box;
        width: 100%;
    }
}