.auto-bet-item {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(27,28,29, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    @media only screen and (max-width: 640px) {
        max-width: unset;
        justify-content: flex-start;
    }

    &__container {
        width: 100%;
        max-width: 500px;
        background: #1b1c1d;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        @media only screen and (max-width: 640px) {
            max-width: unset;
        }
    }

    &__header {
        padding: 0 10px;
        height: 36px;
        min-height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        background: #2c2d30;
        border-radius: 8px 8px 0 0;
        font-weight: 300;
        box-sizing: border-box;
        color: white;
    }

    &__svg {
        height: 13px;
        width: 13px;
        color: #97a4ae;
        display: flex;
        align-items: center;
        justify-content: center;
        &_isError {
            opacity: 0.5;
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 94px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 10px;
        padding-bottom: 15px;
        row-gap: 5px;
        color: white;
    }

    &__auto-bet-count {
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        background: #2c2d30;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px 12px;
        row-gap: 10px;
    }

    &__count-container {
        width: 100%;
        max-width: 290px;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;
    }

    &__count-item {
        width: 58px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #36363c;
        border-radius: 100px;
        background-color: #252528;
        color: #83878e;
        text-align: center;
        box-shadow: none;
        font-size: 14px;
        padding: 4px 8px;
        line-height: 1.5;
        &_isChosen {
            border: solid 1px #60ae05;
            background-color: #545b62;
            color: white;
        }
    }

    &__error-container {
        border-color: #d8001e;
        background-color: #b3021b;
        border: 1px solid transparent;
        color: #fff;
        box-shadow: 0 0 8px #0000004d;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        margin-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
    }

    &__footer {
        width: 100%;
        height: 58px;
        border-radius: 0 0 8px 8px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 14px;
        background: #2c2d30;
        column-gap: 15px;
    }
}