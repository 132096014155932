.my-bets {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    color: #8d97a1;
    padding: 5px 8px 5px 12px;
    background: #252936;
    border-radius: 10px;
    min-height: 50px;
    margin-bottom: 2px;
  }

  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 2px;
  }

  &__item {
    width: 100%;
    height: 34px;
    margin-top: 2px;
    border-radius: 8px;
    background-color: #101112;
    border: solid 1px #101112;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 5px;
    box-sizing: border-box;

    &_isWin {
        border-color: #427f00;
        background-color: #123405;
    }
  }

  &__created-date {
    box-sizing: border-box;
    font-size: 12px;
    color: #bbbfc5;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    width: 16.33%;
    padding-right: 6.24px;
}

&__amount {
    box-sizing: border-box;
    display: flex;
    width: 29.26%;
    justify-content: flex-end;
    font-weight: 300;
}

&__coeff {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 11px;
    border-radius: 11px;
    color: rgb(52, 180, 255);
    background-color: #00000080;
    font-size: 12px;
    min-width: 15.6%;
    margin-left: 10px;
    font-weight: 700;

}

&__win-amount {
    box-sizing: border-box;
    display: flex;
    width: 29.26%;
    justify-content: flex-end;
    margin-left: auto;
    padding-right: 5px;
    font-weight: 300;
}

  &__labels {
    font-size: 11px;
    color: #7b7b7b;
    min-height: 20px;
    max-height: 20px;
    padding: 0px 10px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;

    &_isDate {
      width: 16.47%;
    }

    &_isBet {
      width: 29.26%;
      justify-content: flex-end;
    }

    &_isCoef {
      width: 5%;
      padding-left: 10px;
    }

    &_isCashOut {
      width: 42.95%;
      margin-left: auto;
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 700px) {
  .my-bets {
    &__items {
      max-height: initial;
    }

    &__header {
      &_lang {
        &_it {
          font-size: .75rem;
        }
      }
    }
  }
}

@keyframes increaceResult {
  0% {
    max-height: 0;
    padding: 0 0.5rem;
  }

  100% {
    padding: 1rem 0.5rem;
    max-height: 4rem;
  }
}