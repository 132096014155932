.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: #000;
    border: 1px solid #2a2b2e;
    font-size: 11px;
    color: #5e6269;
    padding: 0 10px;
    column-gap: 4px;
    box-sizing: border-box;
    min-height: 25px;
    margin: 2px;

    &__icon {
        width: 34px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}