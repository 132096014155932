.settings {
  position: absolute;
  right: 0;
  top: 2.5rem;
  min-width: 340px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background: #373c4c;
  box-sizing: border-box;
  box-shadow: 0 5px 20px rgba(0,0,0,0.5);
  padding: 30px 15px 10px 20px;
  text-transform: uppercase;
  transition: max-width 1s, max-height 1s;
  z-index: 1004;
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__text {
    width: 100%;
    padding-right: 15px;
  }
  &__languages_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
  }
  &__lang-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 30px;
    svg {
      width: 20px;
      height: 15px;
    }
    &_activeLang {
      background-color: #252936;
      border-radius: 5px;
    }
    &:hover {
      background: rgba(37,41,54,0.5);
      border-radius: 5px;
    }
  }
}