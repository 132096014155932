.bets {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &__header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #7b7b7b;
    background: transparent;
    min-height: 20px;
    margin: 0 10px;
    font-size: 11px;
  }
  &__items {
    min-height: 374px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    row-gap: 2px;
    margin: 0 5px;
    box-sizing: border-box;
  }
  &__item {
    width: 100%;
    box-sizing: border-box;
    height: 34px;
    border-radius: 8px;
    background-color: #101112;
    border: solid 2px #101112;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    &_winBet {
      border-color: #427f00;
      background-color: #123405;
    }

  }
  &__column {
    display: flex;
    align-items: center;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    justify-content: flex-start;
    font-size: 11px;
    width: 6%;
    &_user {
      width: 19%;
    }
    &_bet {
      min-width: 35%;
      padding-right: 10px;
      justify-content: flex-end;
    }
    &_win {
      width: 40%;
      justify-content: flex-end;
    }
  }
  &__column-icon {
    width: 22%;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: flex-start;
  }

  &__column-amount {
    width: 25%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  &__column-coef {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2px 11px;
    border-radius: 11px;
    color: rgb(52, 180, 255);
    background-color: #00000080;
  }

  &__column-win {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  &__amount {
    box-sizing: border-box;
    display: flex;
    width: 30.14%;
    justify-content: flex-end;
    font-weight: 300;
}

&__coeff {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 11px;
    border-radius: 11px;
    color: rgb(52, 180, 255);
    background-color: #00000080;
    font-size: 12px;
    min-width: 15.6%;
    margin-left: 10px;
    font-weight: 700;

}

&__win-amount {
    box-sizing: border-box;
    display: flex;
    width: 29.26%;
    justify-content: flex-end;
    margin-left: auto;
    padding-right: 5px;
    font-weight: 300;
}

  &__icon {
    height: 30px;
    width: 30px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__total-bets {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 14px;
    height: 25px;
    padding: 0 10px;
    box-sizing: border-box;
    color: white;
  }

  &__bets-amount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 32px;
    border-top: 2px solid #141516;
    border-bottom: 2px solid #141516;
    box-sizing: border-box;
    column-gap: 5px;
  }

  &__user-icon {
    height: 100%;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

@keyframes increaceResult {
  0% {
    max-height: 0;
    padding: 0 0.5rem;
  }
  100% {
    padding: 1rem 0.5rem;
    max-height: 4rem;
  }
}