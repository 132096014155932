.rules-mini {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(27,28,29, 0.7);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 640px) {
        justify-content: flex-start;
      }

    &__container {
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
        background: #f5a623;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        max-width: 500px;
        @media screen and (max-width: 640px) {
            max-width: unset;
          }
    }

    &__header {
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #e69308;
        font-size: 16px;
        padding: 0 10px;
        color: #965419;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px 8px 0 0;
        &_isFooter {
            border-radius: 0 0 8px 8px;
            font-size: 14px;
            justify-content: center;
            text-decoration: underline;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: 16px;
    }

    &__video {
        display: block;
        width: calc(100% + 32px);
        min-height: 193.5px;
        margin-left: -16px;
        margin-right: -16px;
        position: relative;
    }

    &__video-frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        overflow: hidden;
    }

    &__item {
        color: #5f3816;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        column-gap: 15px;
    }

    &__step {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: url('../../../services/img/01-bet.png') no-repeat right;
        background-size: 40px 40px;
        min-width: 65px;
        max-width: 65px;
        height: 40px;
        font-size: 18px;
        font-weight: 700;
        &_isWatch {
            background: url('../../../services/img/02-watch.png') no-repeat right;
            background-size: 40px 40px;
        }
        &_isCashout {
            background: url('../../../services/img/03-cashout.png') no-repeat right;
            background-size: 40px 40px;
        }
    }

    &__text {
        font-size: 14px;
        text-align: left;
        line-height: 1.5;
    }

    &__close {
        color: rgba(73, 41, 12, 0.5);
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}