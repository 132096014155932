.menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    &__container {
        margin-top: 33px;
        border-radius: 6px;
        max-width: 320px;
        width: 100%;
        height: 484px;
        background: #2c2d30;
        box-shadow: 10px 10px 20px #000000b3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    &__header {
        width: 100%;
        height: 62px;
        border-radius: 6px 6px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
    }

    &__user-info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 50%;
        box-sizing: border-box;
    }

    &__icon {
        width: 40px;
        height: 40px;
    }

    &__change-icon {
        background: #252528;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #83878e;
        padding: 6px 15px 6px 10px;
        font-size: 12px;
        cursor: pointer;
        width: auto;
        border: solid 1px #414148;
        border-radius: 21px;
    }

    &__person-icon {
        width: 20px;
        height: 20px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: 12px;
        font-weight: 200;
    }

    &__content {
        width: 100%;
        height: calc(100% - 106px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    &__settings-items {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    &__items-container {
        width: 100%;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    &__footer {
        width: 100%;
        height: 44px;
        border-radius: 0 0 6px 6px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}