.free-bets {
    position: absolute;
    z-index: 1100;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(17, 19, 24, 0.4);
    box-sizing: border-box;
    @media screen and (max-width: 640px) {
        justify-content: flex-start;
    }


    &__container {
        width: 100%;
        max-width: 500px;
        background: #1b1c1d;
        border-radius: 8px;
        @media screen and (max-width: 640px) {
            max-width: unset;
        }
    }

    &__header {
        padding: 0 10px;
        height: 36px;
        min-height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        background: #2c2d30;
        border-radius: 8px 8px 0 0;
        font-weight: 400;
        box-sizing: border-box;
        color: white;
        text-transform: uppercase;
    }

    &__svg {
        height: 13px;
        width: 13px;
        color: #97a4ae;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__content {
        box-sizing: border-box;
        width: 100%;
        padding: 16px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: 12px;
        box-sizing: border-box;
    }

    &__play-with-cash {
        border: solid 1px #229607;
        display: flex;
        align-items: center;
        width: 100%;
        height: 34px;
        border-radius: 8px;
        background-color: #0707084d;
        margin: 5px auto 0;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 200;
        color: #d2d2d2;
        column-gap: 12px;
    }

    &__circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        min-width: 16px;
        height: 16px;
        background-color: #1b1c1d;
        border: solid 1px #38ca07;
        border-radius: 50%;
        margin-left: 9px;
        box-sizing: border-box;
    }

    &__center {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #38ca07;
        box-sizing: border-box;
    }

    &__active-free-bets {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 5px;
        font-size: 14px;
    }

    &__archive-button {
        display: flex;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        color: #b8b8b7;
        border-radius: 17px;
        border: solid 1px #414148;
        background-color: #252528;
        box-sizing: border-box;
        padding: 2px 10px 2px 5px;
    }

    &__archive-icon {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        box-sizing: border-box;
    }

    &__tickets-container {
        width: 100%;
        height: 100%;
        padding: 30px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
    }

    &__tickets {
        width: 100px;
        height: 62px;
        box-sizing: border-box;
    }

    &__ticket-text {
        margin-top: 14px;
        box-sizing: border-box;
    }
}