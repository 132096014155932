.basket {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  font-weight: normal;
  display: flex;
  color: #8d97a1;
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
  @media screen and (max-width: 990px) {

  }
  @media screen and (max-width: 640px) {
    padding: 0px 5px 5px 5px;
    column-gap: 5px;
  }

  &__label {
    margin-bottom: 15px;
    color: white;
  }


  &_isDouble {
    width: 100%;
    border-radius: 10px 0 0 10px;
    column-gap: 10px;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      border-radius: 10px 0 0 10px;
      row-gap: 5px;
    }

    &.basket-desktop_lang {
      &_fa {
        font-size: .75rem;
      }

      &_it {
        font-size: .85rem;
      }
    }

    // &:first-child {
    //   padding-right: 0.5rem;
    // }
    // &:last-child {
    //   padding-left: 0.5rem;
    // }
    // &:nth-child(2) {
    //   border-radius: 0 10px 10px 0;
    // }
    .basket-desktop {

      &__more-basket {
        width: 9%;
        height: 100%;
        padding: 0 1rem;
        margin-left: 1rem;
      }

      &__more-plus {
        width: 1.6rem;
        margin-top: 0;
        line-height: 0;
      }

      &__switch-container {
        width: 24%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: .5rem;
      }

      &__bet-block {
        width: calc(25% - .75rem);
        text-align: center;
      }

      &__row {
        padding: .5rem 0;
      }

    }
  }

  // &_isBlock {
  //   .basket-desktop__row_alwaysActive {
  //     > * {
  //       position: relative;
  //     }
  //   }
  //   .basket-desktop__row:not(.basket-desktop__row_alwaysActive) {
  //     * {
  //       cursor: not-allowed !important;
  //     }
  //     .button {
  //       cursor: pointer !important;
  //     }
  //   }
  // }
  &__more-basket {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background: #427f00 url('../../services/img/plus.png') no-repeat center center;

    &_isRemove {
      background: url('../../services/img/remove.png') no-repeat;
    }
  }

  &__more-text {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  &__more-plus {
    width: 2.5rem;
    margin-top: 10px;
  }

  &__change-icon {
    display: block;
    height: 1.4rem;
    width: 1.4rem;

    * {
      fill: #FFF;
    }
  }

  &__remove-icon {
    width: 1.5rem;
    margin-top: 10px;
  }




}