.animation-container {
    display: flex;
    flex-direction: column;
    height:  30px;
    width: 100%;
    padding-left: 26.5px;
    z-index: 10;

    &_isVertical{
        flex-direction: row-reverse;
        height: 100%;
        width: 30px;
        margin-left: 0;
        z-index: 100;
        padding-left: unset;
    }
    &__horizont-shadow {
        background: #3F3133;
        width: 100%;
        display: flex;
        height: 1px;
        &_isVertical {
            background: #3F3133;;
            width: 1px;
            height: 100%;
        }
    }
    &__to-left-animation {
        display: flex;
        width: 100%;
        height: calc(100% - 1px);
        align-items: center;
        background: 0 50% repeat-x url('../../../../services/img/dot.png');
        &_isVertical {
            height: 100%;
            width: calc(100% - 1px);
            background: 50% 100% repeat-y url('../../../../services/img/dotV.png');
        }
    }
}