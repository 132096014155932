.statistic-component {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 900;
    padding-top: 43px;
    @media screen and (max-width: 990px) {
        justify-content: center;
    }

    &__container {
        border-radius: 16px;
        box-shadow: 0 2px 20px #00000080;
        background-color: #262830;
        margin: 0 10px;
        width: calc(100% - 440px);
        box-sizing: border-box;
        @media screen and (max-width: 990px) {
            margin: 0 5px;
            width: 100%;
        }
        
    }

    &__header {
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding: 0 10px;
        background: #2c2d30;
        border-radius: 16px 16px 0 0;
        text-transform: uppercase;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        border-radius: 0 0 16px 16px;
        font-size: 12px;

        &::-webkit-scrollbar {
            display: none;
          }

    }

    &__bets-container {
        height: 100%;
        min-height: 230px;
        font-size: 15px;
        line-height: 19px;
    }

}

.bets-coef {
    box-sizing: border-box;
    margin: 3px;
    cursor: pointer;
    padding: 2px 11px;
    border-radius: 11px;
    font-weight: 700;
    font-size: 12px;
    background-color: #00000080;
    border-radius: 11px;
    line-height: 1.5;
}