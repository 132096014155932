.tickets-component {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 19, 24, 0.9);
    z-index: 1000000;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 45px;
        border-radius: 10px;
        background: #252936;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        width: 42.85rem;
        height: 20.36rem;
        overflow: hidden;
        margin-top: 13rem;
        margin-bottom: auto;
        animation: openComponent .3s ease-out;
        &_isClose {
            animation: closeComponent .3s ease-out;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #2d313f;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 3.9rem;
        padding: 15px;
        box-sizing: border-box;
    }

    &__svg {
        height: 21px;
        width: 16.1px;
        color: rgb(141, 151, 161);

        &:hover {
            color: rgb(255, 204, 0);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;
    }

    &__tickets {
        height: 80px;
        width: 80px;
    }

    &__icon {
        width: 100%;
        height: 100%;
    }

    &__text {
        color: #86878c;
        font-size: 15px;
    }
}

@keyframes openComponent {
    from {
        transform: translate(0,-25%);
    }

    to {
        transform: translate(0,0);
    }
}

@keyframes closeComponent {
    from {
        transform: translate(0,0);
        opacity: 1;
    }

    to {
        transform: translate(0,-25%);
        opacity: 0;
    }
}