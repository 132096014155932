.home-button {
    width: auto;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 200;
    color: #9ea0a3;

    &__home-icon {
        height: 100%;
        width: 16px;
        margin-right: 5px;
    }

    &__home-text {
        margin-top: 2px;
    }
}