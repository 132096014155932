.game {
  height: 100%;
  width: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__left-header-layout {
    width: 100%;
    height: 38px;
  }

  &__statistic-layout {
    width: 100%;
    height: 22px;
    margin-bottom: 10px;
    min-height: 22px;
    max-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 990px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__game-components {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 410px);
    margin: 10px;
    box-sizing: border-box;
    min-width: 0;
    @media screen and (max-width: 1199px) {
      width: calc(100% - 320px);
    }
    @media screen and (max-width: 990px) {
      width: 100%;
      margin: unset;
    }
  }

  &__jackpot-layout {
    width: 100%;
    box-sizing: border-box;
    min-height: 22px;
    max-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
  }

  &__history-layout {
    min-width: 410px;
    max-width: 410px;
    padding-left: 10px;
    padding-top: 10px;
    height: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 1199px) {
      min-width: 320px;
      max-width: 320px;
    }
    @media screen and (max-width: 990px) {
      width: 100%;
      min-width: unset;
      max-width: unset;
      height: 100%;
      margin-left: 0;
      padding: 20px 5px 0 5px;
    }
  }

  &__game-layout {
    position: relative;
    background: #1c1f2c;
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #2a2b2e;
    @media screen and (max-width: 990px) {
      height: calc(40vh - 38px);
      min-height: 200px;
    }
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
  
  &__vertical-animation {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
  }

  &__game-window-container {
    width: 100%;
    height: 100%;
  }

  &__basket-layout {
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 990px) {
      margin-top: 5px;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: 'mtavruli', Arial;
    text-transform: uppercase;
    height: 100%;
    line-height: 14px;
    width: 20%;
    svg {
      width: 32px;
      height: 22px;
    }
  }
}