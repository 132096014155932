.switch-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  padding: 0 2px;
  width: 36px;
  min-width: 36px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid rgba(255,255,255,.51);
  background: none;
  justify-content: flex-start;
  box-sizing: border-box;
  &_active {
    border: solid 1px #60ae05;
    background-color: #229607;
    justify-content: flex-end;
  }

  &__indicator {


    min-width: 14px;
    max-width: 14px;
    height: 14px;
    box-shadow: inset 0 0 7px #00000029;
    background-color: #fff;
    border: solid 2px #a3b98a;
    border-radius: 50%;
    // transition: all .3s ease-out;
    box-sizing: border-box;
  }
}