.button {
  color: #fff;
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  outline: none;
  white-space: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  &__text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  // &_isBlock {
  //   outline: rgba(0, 0, 0, 0.4) solid 20rem;
  // }

  &_isDisabled {
    opacity: .5;
    cursor: not-allowed !important;
  }

  &_isUppercase {
    text-transform: uppercase;
  }
  &_isBold {
    font-weight: bold;
  }
  &_color {
    &_orange {
      transform: translate(0px);
      width: 100px;
      background: rgb(44, 45, 48);
      height: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      color: white;
      font-size: 12px;
      cursor: pointer;
      font-weight: 200;
      text-overflow: ellipsis;
    }
    &_dark {
      width: 100px;
      height: 100%;
      border-radius: 20px;
      text-align: center;
      background: none;
      border: none;
      color: #9ea0a3;
      padding: 0;
      font-size: 12px;
      cursor: pointer;
      font-weight: 200;
      text-overflow: ellipsis;
    }
    &_blue {
      height: 22px;
      width: 100px;
      padding: 0;
      font-size: 12px;
      border: solid 1px #46c0f2;
      border-radius: 17px;
      background-color: #1d7aca;
      color: #fff;
      text-align: center;
      box-shadow: none;
      cursor: pointer;
      text-overflow: ellipsis;
    }
    &_red {
      background: #cb011a;
      border: solid 1px #ff7171;
      font-size: 21px;
      color: #fff;
      cursor: pointer;
      border-radius: 20px;
      font-weight: 400;
      text-overflow: ellipsis;
      @media only screen and (max-width: 422px) {
        font-size: 20px;
      }
    }
    &_active-red {
      background: #cb011a;
      border: solid 1px #ff7171;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      cursor: pointer;
      border-radius: 20px;
      font-weight: 400;
      height: 70%;
      text-overflow: ellipsis;
    }
    &_yellow {
      border: solid 1px #ffbd71;
      background-color: #d07206;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      border-radius: 20px;
      font-weight: 400;
      text-overflow: ellipsis;
      cursor: pointer;
      @media only screen and (max-width: 422px) {
        font-size: 20px;
      }
    }
    &_green {
      box-sizing: border-box;
      border: solid 1px #b2f2a3;
      background-color: #28a909;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      font-size: 20px;
      cursor: pointer;
      border-radius: 20px;
      font-weight: 400;
      text-overflow: ellipsis;
    }
    &_orangeReset {
      min-width: 80px;
      height: 22px;
      border: solid 1px #ffbd71;
      background-color: #d07206;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      border-radius: 20px;
      font-weight: 400;
      line-height: 1.5;
      font-size: 14px;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    &_greenStart {
      width: 160px;
      height: 30px;
      border: solid 1px #b2f2a3;
      background-color: #28a909;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      cursor: pointer;
      border-radius: 20px;
      font-weight: 400;
      line-height: 1.5;
      font-size: 20px;
      text-overflow: ellipsis;
    }
    &_redStop {
      height: 22px;
      width: 100px;
      padding: 0;
      font-size: 12px;
      border: solid 1px #ff7171;
      background-color: #cb011a;
      box-shadow: inset 0 1px 1px #ffffff80;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0,0,0,.5);
      border-radius: 20px;
      text-transform: lowercase;
      text-overflow: ellipsis;
    }
  }
  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
  }
  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;
  }
}