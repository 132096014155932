.top-wins {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
    row-gap: 5px;
    padding: 0 5px;
  }
  &__item {
    width: 100%;
    box-sizing: border-box;
    color: #9ea0a3;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
  &__main-block {
    height: 80px;
    width: 100%;
    background: #101112;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__user-info {
    margin-left: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: calc(50% - 22.5px);
    left: 0;
  }
  &__user-name {
    font-size: 13px;
  }
  &__icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-style: none;
  }
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }
  &__text {
    font-size: 14px;
    color: white;
  }
  &__coef {
    box-sizing: border-box;
    margin-left: 5px;
    padding: 2px 11px;
    border-radius: 11px;
    color: rgb(192, 23, 180);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) {
  .top-wins {
    max-height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &__arrow {
      display: none;
    }
    &__item {
      width: 100%;
    }
  }
}