.statistic {
  width: 100%;
  display: flex;
  padding: 0px 10px 0px 5px;
  box-sizing: border-box;
  font-size: 12px;
  height: 22px;
  overflow: hidden;
  align-items: center;
  align-items: center;
  position: relative;

  &__coefs-container {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
  }
  &__coef {
    font-weight: 700;
    cursor: pointer;
    display: flex;
    margin: 0px 4px 0px 1px;
    padding: 2px 11px;
    border-radius: 11px;
    box-sizing: border-box;
    background-color: #00000080;
  }
  &__statistic-button {
    position: absolute;
    top: 0;
    right: 10px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 22px;
    border-radius: 13px;
    border: solid 1px #414148;
    background: #252528;
    box-sizing: border-box;
    padding: 0 8px;
    cursor: pointer;
    z-index: 1000;
  }

  &__history {
    color: #767b85;
    width: 14px;
    height: 13px;
    margin-right: 5px;
    &_isOpen {
      color: rgb(229, 5, 57);
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__arrow {
    color: #767b85;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;

    &_isOpen {
      color: rgb(229, 5, 57);
      transform: rotate(180deg);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 700px) {
  .statistic {
    // height: 100%;
    // padding: 0.5rem .6rem;

    // &__coefs-container {
    //   &_isOpen {
    //     height: 9rem;
    //   }
    // }
    // &_isOpen {
    //   height: 9rem;
    // }
    // &__coef {
    //   width: 10.5%;
    //   height: 1.25rem;
    //   margin-bottom: .6rem;
    // }
  }
}

@keyframes increace {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes reduce {
  0% {
    display: flex;
    max-height: 100vh;
  }
  99% {
    display: flex;
  }
  100% {
    display: none;
    max-height: 0;
  }
}

@keyframes increaseCoef {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 10.5%;
  }
}
