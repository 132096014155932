#notify {
  position: fixed;
  width: 300px;
  overflow: hidden;
  z-index: 2000;
  left: 50%;
  transform: translate(-50%);
  top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  #notify {
    width: 70%;
  }
}