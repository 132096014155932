.coef {
  font-weight: 600;
  font-size: 110px;
  color: #FFFFFF;
  // padding: 0.3rem;
  position: absolute;
  white-space: nowrap;
  top: initial;
  right: initial;
  bottom: calc(50% - 3rem);
  border-radius: 7px;
  z-index: 2;
  &_isCrash {
    color: red;
  }
  @media only screen and (max-width: 422px) {
    font-size: 70px;
  }
}

@media screen and (min-width: 700px) {
  .coef {
    top: initial;
  }
}