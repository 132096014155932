.firework {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__animation-container {
    background: url('../../../../../services/img/line.webp');
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    &_isAnimation {
      animation: 
      animationContainerStart 15s ease-in forwards,
      animationContainer 5s linear 15s infinite;

      @media screen and (max-width: 990px) {
        animation: 
          animationContainerStart990 15s ease-in forwards,
          animationContainer990 5s linear 15s infinite;
      }

      @media screen and (max-width: 640px) {
        animation: 
          animationContainerStart640 15s ease-in forwards,
          animationContainer640 5s linear 15s infinite;
      }
      // &.firework__animation-container_isHorizon {
      //   animation: animationContainerHorizonStart 15s ease-in forwards,
      //    animationContainerHorizon 5s linear 15s infinite;
      // }
    }

    &_isHorizon {
      background: none;
    }

    &_isPauseAnimation {
      animation-play-state: paused !important;
    }
  }

  &__rocket {
    height: 112px;
    width: 150px;
    position: absolute;
    top: -70px;
    right: -128px;
    animation: none;

    @media screen and (max-width: 640px) {
      transform: scale(0.6);
      top: -64px;
      right: -105px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__blow {
    width: 112px;
    height: 150px;
    position: absolute;
    top: -70px;
    right: -128px;
    animation: 4s ease-in-out flyAway;
    animation-iteration-count: 1;

    @media screen and (max-width: 640px) {
      transform: scale(0.6);
      top: -64px;
      right: -105px;
    }
  }
}

@keyframes flyAway {
  from {
    top: -70px;
    right: -128px;

    @media screen and (max-width: 640px) {
      top: -64px;
      right: -105px;
    }
  }

  to {
    top: -800px;
    right: -928px;
  }
}

@media screen and (max-width: 700px) {
  // .firework {
  //   height: 10rem;
  // }
}

@keyframes animationContainer {
  0% {
    width: calc(75% - 6rem);
    height: calc(100% - 8rem);
  }

  50% {
    width: calc(95% - 7rem);
    height: calc(60% - 8rem);
  }

  100% {
    width: calc(75% - 6rem);
    height: calc(100% - 8rem);
  }
}

@keyframes animationContainer990 {
  0% {
    width: calc(65% - 10rem);
    height: calc(110% - 9rem);
  }

  50% {
    width: calc(95% - 7rem);
    height: calc(65% - 8rem);
  }

  100% {
    width: calc(65% - 10rem);
    height: calc(110% - 9rem);
  }
}

@keyframes animationContainer640 {
  0% {
    width: calc(75% - 10rem);
    height: calc(125% - 8rem);
  }

  50% {
    width: calc(125% - 12rem);
    height: calc(70% - 8rem);
  }

  100% {
    width: calc(75% - 10rem);
    height: calc(125% - 8rem);
  }
}



@keyframes hideAndVisible {
  0% {
    transform: rotate(0) scale(0.5);
  }

  25% {
    transform: rotate(0) scale(1.15);
  }

  50% {
    transform: rotate(0) scale(0.9);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes animationStart990 {
  0% {
    width: 3rem;
    height: 1rem;
  }

  100% {
    width: calc(65% - 10rem);
    height: calc(110% - 9rem);
  }
}

@keyframes animationContainerStart {
  0% {
    width: 3rem;
    height: 1rem;
  }

  100% {
    width: calc(75% - 6rem);
    height: calc(100% - 8rem);
  }
}

@keyframes animationContainerStart640 {
  0% {
    width: 3rem;
    height: 1rem;
  }

  100% {
    width: calc(75% - 10rem);
    height: calc(125% - 9rem);
  }
}

@keyframes animationContainerStart990 {
  0% {
    width: 3rem;
    height: 1rem;
  }

  100% {
    width: calc(65% - 10rem);
    height: calc(110% - 9rem);
  }
}

@keyframes animationContainerHorizonStart {
  0% {
    width: 3rem;
    height: 1rem;
  }

  100% {
    width: calc(95% - 9rem);
    height: 1rem;

    @media screen and (max-width: 640px) {
      width: calc(95% - 10rem);
      height: 1rem;
    }
  }
}

@keyframes animationContainerHorizon {
  0% {
    width: calc(95% - 9rem);

    @media screen and (max-width: 640px) {
      width: calc(95% - 10rem);
    }
  }

  25% {
    width: calc(95% - 8rem);

    @media screen and (max-width: 640px) {
      width: calc(90% - 8rem);
    }
  }

  75% {
    width: calc(95% - 10rem);

    @media screen and (max-width: 640px) {
      width: calc(90% - 12rem);
    }
  }

  100% {
    width: calc(95% - 9rem);

    @media screen and (max-width: 640px) {
      width: calc(95% - 10rem);
    }
  }
}