.rules {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 19, 24, 0.9);
  padding: 10px;
  z-index: 1100;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: #1f212e;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    width: 100%;
    max-width: 68.7%;
    height: 100%;
    border-radius: 8px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    animation: openComponent .3s ease-out;

    @media screen and (max-width: 640px) {
      max-width: unset;
    }

    &_isClose {
      animation: closeComponent .3s ease-out;
    }
  }

  &__svg {
    height: 13px;
    width: 13px;
    color: #97a4ae;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__header {
    padding: 0 10px;
    height: 36px;
    min-height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    background: #2c2d30;
    border-radius: 8px 8px 0 0;
    font-weight: 400;
    box-sizing: border-box;
    color: white;
    text-transform: uppercase;
  }

  &__content {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    min-height: 0;
  }

  &__bets-container {
    height: 100%;
    min-height: 230px;
    font-size: 15px;
    line-height: 19px;
  }

  &__text {
    // overflow: hidden;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// @media screen and (min-width: 700px) {
//   .rules {
//     padding: 1rem 15%;

//     &__header {
//       margin-bottom: 0.5rem;
//     }

//     &__button-container {
//       right: 15%;
//       top: 0.75rem;
//     }
//   }
// }

@keyframes openComponent {
  from {
    transform: translate(0, -25%);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes closeComponent {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, -25%);
    opacity: 0;
  }
}